import { BoxHeaderProps } from './props';
import { StyledBox, StyledHeadingInner, StyledTitle } from './styles';

export const BoxHeader: React.FC<BoxHeaderProps> = (props) => {
  // ======================= PROPS
  const { title, children } = props;

  // ======================= VIEWS
  return (
    <div>
      <StyledHeadingInner>
        <StyledTitle>
          <StyledBox>{title}</StyledBox>
        </StyledTitle>
      </StyledHeadingInner>
      <div className="box-children">{children}</div>
    </div>
  );
};

// ======================= EXPORT
export * from './props';
export default BoxHeader;
