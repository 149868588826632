import { Stack } from '@mui/material';

import { TitleDividerProps } from './props';
import StyledTitle, { Box, TitleDividerWrapper } from './styles';

export const TitleDivider: React.FC<TitleDividerProps> = (props) => {
  // ======================= PROPS
  const { title, children, paddingBlock, webView, rightContent } = props;

  // ======================= VIEWS
  if (!title) return children;

  if (webView) {
    return (
      <div>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          sx={{ marginBottom: { xs: 0, md: '12px' } }}
        >
          <StyledTitle>{title}</StyledTitle>
          {rightContent}
        </Stack>
        <div className="box-children">{children}</div>
      </div>
    );
  }

  return (
    <Box className="box" paddingBlock={paddingBlock}>
      <TitleDividerWrapper className="box-wrapper">
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          sx={{ marginBottom: { xs: 0, md: '12px' } }}
        >
          <StyledTitle>{title}</StyledTitle>
          {rightContent}
        </Stack>
        <div className="box-children">{children}</div>
      </TitleDividerWrapper>
    </Box>
  );
};

// ======================= EXPORT
export * from './props';
export default TitleDivider;
