import { useState } from 'react';
import { Checkbox, Collapse, TableCell } from '@mui/material';
import MUITableRow from '@mui/material/TableRow';

import { TableColumnType } from '../@types';

import { TableRowProps } from './props';

export const TableRow = <T,>(props: TableRowProps<T>) => {
  // ======================= PROPS
  const {
    collapsible,
    collapsibleColSpan,
    data,
    columns = [],
    checked = false,
    onCheck,
    onClick,
    onExpand,
    renderExpandedView,
    ...restProps
  } = props;

  // ======================= STATE
  const [expanded, setExpanded] = useState(false);

  // ======================= EVENTS
  const onClickRow = (e: React.MouseEvent<HTMLElement>) => {
    onClick?.(data, e);
    if (!collapsible) return;
    setExpanded(!expanded);
  };

  // ======================= RENDER FUNCTIONS
  const renderColumnData = (column: TableColumnType<T>, index: number) => {
    const { key, type, dataIndex, render } = column;
    const result = dataIndex ? data[dataIndex] : data;

    if (render) {
      return render(result, data, index);
    }

    if (type === 'checkbox') {
      const id = `checkbox-${key}-${index}`;
      return (
        <Checkbox
          color="primary"
          checked={checked}
          inputProps={{ 'aria-labelledby': id }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const checkedState = event.target.checked;
            onCheck?.(data, checkedState, {
              index,
              dataIndex,
              data,
            });
          }}
        />
      );
    }

    if (typeof result === 'object') {
      return;
    }
    return result;
  };

  // ======================= VIEWS
  return (
    <MUITableRow onClick={onClickRow} {...restProps}>
      {columns.map((column, index) => {
        const id =
          column.type === 'checkbox'
            ? `checkbox-${column.key}-${index}`
            : undefined;
        return (
          <TableCell
            id={id}
            padding={column.type === 'checkbox' ? 'checkbox' : undefined}
            key={`${column.key}-${index}`}
            align={column.align ?? 'left'}
            {...column.dataCellProps}
            sx={{
              width: column.width,
              ...column.dataCellProps?.sx,
            }}
          >
            <>{renderColumnData(column, index)}</>
          </TableCell>
        );
      })}
      {collapsible && (
        <MUITableRow {...restProps}>
          <TableCell
            style={{ padding: 0 }}
            colSpan={collapsibleColSpan ?? columns.length}
          >
            <Collapse
              timeout="auto"
              in={expanded}
              unmountOnExit
              addEndListener={() => onExpand?.(data, expanded)}
            >
              {renderExpandedView?.(data, expanded)}
            </Collapse>
          </TableCell>
        </MUITableRow>
      )}
    </MUITableRow>
  );
};

// ======================= EXPORT
export * from './props';
export default TableRow;
