/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper as SwiperJS, SwiperClass, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { NavigationButton } from '../NavigationButton';

import { SwiperProps, SwiperRefProps } from './props';

import 'swiper/css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Swiper = forwardRef<SwiperRefProps, SwiperProps<any>>(
  (props, ref) => {
    const {
      list = [],
      renderSlide,
      navigation = true,
      height = '100%',
      width = '100%',
      autoPlay = false,
      swiperStyle,
      children,
      onSwiper,
      onSlideChange,
      className,
      swiperSlideProps,
      spaceBetween = 0,
      slidesPerView,
      isCustomSlidesPerView = false,
      paginationProps,
      updateHasNext,
      updateHasPrev,
      containerProps,
      navigationProps,
      renderPreContent,
      isSliderButtonCentered = false,
      renderPostContent,
      breakpoints,
      loading,
      ...restProps
    } = props;

    // ======================= HOOKS
    const swiperRef = useRef<SwiperClass | null>(null);

    // ======================= STATE
    const [type, setType] = useState('start');

    // ======================= EFFECT
    useEffect(() => {
      updateHasNext?.(!swiperRef.current?.isEnd);
    }, [swiperRef.current?.isEnd]);

    useEffect(() => {
      updateHasPrev?.(!swiperRef.current?.isBeginning);
    }, [swiperRef.current?.isBeginning]);

    // ======================= EVENTS
    useImperativeHandle(ref, () => ({
      // expose slidenext and slideprev function
      slideNext: () => {
        if (swiperRef.current) {
          swiperRef?.current?.slideNext();
        }
      },
      slidePrev: () => {
        if (swiperRef.current) {
          swiperRef?.current?.slidePrev();
        }
      },
      activeIndex: swiperRef?.current?.activeIndex ?? 0,
      currentBreakpoint: swiperRef?.current?.currentBreakpoint ?? '0',
    }));

    // ======================= VARIABLES
    const isStart = type === 'start' ? 'hidden' : 'visible';
    const isEnd = type === 'end' ? 'hidden' : 'visible';
    const viewXs = slidesPerView?.extraSmallMobile ?? 1;
    const viewSm = slidesPerView?.mobile ?? 1;
    const viewMd = slidesPerView?.ipad ?? 1;
    const viewLg = slidesPerView?.desktop ?? 1;
    const viewXl = slidesPerView?.extraBigScreen ?? 1;
    const customSlidePerView = isCustomSlidesPerView
      ? { slidesPerView: slidesPerView?.custom }
      : {
          breakpoints: {
            0: {
              slidesPerView: viewXs,
            },
            576: {
              slidesPerView: viewSm,
            },
            992: {
              slidesPerView: viewMd,
            },
            1280: {
              slidesPerView: viewLg,
            },
            1800: {
              slidesPerView: viewXl,
            },
            ...breakpoints,
          },
        };

    const endBreakPoint = {
      xs: list.length > (viewXs ?? 1) ? isEnd : 'hidden',
      sm: list.length > (viewSm ?? 1) ? isEnd : 'hidden',
      md: list.length > (viewMd ?? 1) ? isEnd : 'hidden',
      lg: list.length > (viewLg ?? 1) ? isEnd : 'hidden',
      xl: list.length > (viewXl ?? 1) ? isEnd : 'hidden',
    };

    // ======================= RENDER FUNCTIONS
    const renderContent = () => {
      return list.map((i, index) => (
        <SwiperSlide key={index} {...swiperSlideProps}>
          {renderSlide?.(i, index)}
        </SwiperSlide>
      ));
    };

    const renderNavigationButton = () => {
      if (!navigation || loading) return null;
      return (
        <>
          <NavigationButton
            onClick={() => swiperRef?.current?.slidePrev()}
            direction="prev"
            {...navigationProps}
            sx={{
              visibility: isStart,
              position: 'absolute',
              left: -15,
              top: isSliderButtonCentered ? '45%' : 'calc(50% - 60px)',
              ...navigationProps?.sx,
            }}
          />
          <NavigationButton
            onClick={() => swiperRef?.current?.slideNext()}
            direction="next"
            {...navigationProps}
            sx={{
              visibility: endBreakPoint,
              position: 'absolute',
              right: -15,
              top: isSliderButtonCentered ? '45%' : 'calc(50% - 60px)',
              ...navigationProps?.sx,
            }}
          />
        </>
      );
    };

    // ======================= VIEWS
    return (
      <Box position={'relative'} {...containerProps}>
        <SwiperJS
          modules={[Navigation, Pagination, Autoplay]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
            swiper.navigation?.init();
            swiper.navigation?.update();
          }}
          autoplay={autoPlay}
          pagination={{ clickable: true, ...paginationProps }}
          onSwiper={onSwiper}
          onSlideChange={(e) => {
            setType(e?.isBeginning ? 'start' : e?.isEnd ? 'end' : '');
            onSlideChange?.(e);
          }}
          spaceBetween={spaceBetween}
          {...customSlidePerView}
          className={classNames('w-full', className)}
          {...restProps}
          style={{
            width,
            height,
            ...swiperStyle,
          }}
        >
          {renderPreContent?.()}
          {renderContent()}
          {renderPostContent?.()}
        </SwiperJS>
        {renderNavigationButton()}
      </Box>
    );
  }
);

export default Swiper;
