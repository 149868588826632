// import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';

import { NavigationButtonProps } from './props';
import { StyledNextIcon, StyledPrevIcon } from './styled';

export const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
  const { size, direction, onClick, ...restProps } = props;
  return (
    <IconButton
      size={size}
      onClick={onClick}
      {...restProps}
      color="secondary"
      sx={{
        zIndex: 1,
        alignItem: 'center',
        justifyContent: 'center',
        display: 'flex',
        boxShadow: '0px 2.333px 28px 0px rgba(0, 0, 0, 0.20)',
        background: '#FFF',
        '&:hover': {
          background: '#FFF',
          boxShadow: '0px 2.333px 28px 0px rgba(0, 0, 0, 0.40)',
        },
        ...restProps.sx,
      }}
    >
      {direction === 'prev' ? <StyledPrevIcon /> : <StyledNextIcon />}
    </IconButton>
  );
};
