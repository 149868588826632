import { ReactNode } from 'react';
import Link from 'next/link';

import { BreadcrumbProps } from './props';
import StyledBreadcrumb, {
  StyledBreadcrumbHolder,
  StyledBreadcrumbItem,
  StyledBreadcrumbItemV2,
  StyledBreadcrumbText,
  StyledLastText,
} from './styles';

export const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  // ======================= PROPS
  const { options, bold = false } = props;

  // ======================= VIEWS
  const renderBreadcrumbItem = (children: ReactNode, key: string) => {
    if (bold)
      return (
        <StyledBreadcrumbItemV2 key={key}>{children}</StyledBreadcrumbItemV2>
      );
    return <StyledBreadcrumbItem key={key}>{children}</StyledBreadcrumbItem>;
  };
  return (
    <StyledBreadcrumb className="Breadcrumb">
      <StyledBreadcrumbHolder>
        {options.map((opt) => {
          return renderBreadcrumbItem(
            opt.url ? (
              <Link href={opt.url} key={opt.url}>
                <StyledBreadcrumbText className="text-sm">
                  {opt.title}
                </StyledBreadcrumbText>
              </Link>
            ) : (
              <StyledLastText key={opt.url} className="text-sm">
                {opt.title}
              </StyledLastText>
            ),
            opt.title as string
          );
        })}
      </StyledBreadcrumbHolder>
    </StyledBreadcrumb>
  );
};

// ======================= EXPORT
export default Breadcrumb;
