import React from 'react';
import { useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';

import { ChartProps } from '../props';
import ReactApexcharts from '../react-apexchart';

export const DonutChart: React.FC<ChartProps> = (props) => {
  const { colors, data } = props;
  const theme = useTheme();

  const options: ApexOptions = {
    stroke: { width: 3 },
    labels: data?.labels ?? [],
    colors: colors,
    chart: {
      background: 'transparent',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    theme: {
      mode: 'light',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            name: {
              show: false,
            },
            show: true,
            value: {
              fontSize: '1.2rem',
              fontWeight: 500,
              fontFamily:
                "Inter,sans-serif,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
              color: theme.palette.text.primary,
              formatter: (val: string) => `${parseInt(val, 10)}`,
              offsetY: 5,
            },
            total: {
              show: true,
              showAlways: false,
              fontSize: '1rem',
              label: '',
              fontFamily:
                "Inter,sans-serif,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
              fontWeight: 500,
              formatter: () => data?.total?.toString() ?? '0',
              //  color: theme.palette.text.secondary,
            },
          },
        },
      },
    },
    responsive: [
      {
        options: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  value: {
                    fontSize: '1rem',
                  },
                  total: {
                    fontSize: '1rem',
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  return (
    <ReactApexcharts
      type="donut"
      height={200}
      width={200}
      options={options}
      series={data?.series ?? []}
    />
  );
};

export default DonutChart;
