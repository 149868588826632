import React from 'react';
import { useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';

import { ChartProps } from '../props';
import ReactApexcharts from '../react-apexchart';

export const LineChart: React.FC<ChartProps> = (props) => {
  const { colors, data } = props;
  const theme = useTheme();
  const options: ApexOptions = {
    chart: {
      parentHeightOffset: 0,
      background: 'transparent',
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    colors: colors,
    stroke: { curve: 'straight', width: 2 },
    dataLabels: { enabled: false },
    markers: {
      size: 5,
      strokeWidth: 2,
      strokeOpacity: 1,
      colors: ['#fff'],
      strokeColors: colors,
    },
    grid: {
      strokeDashArray: 10,
      padding: { top: -10 },
      borderColor: theme.palette.divider,
      xaxis: {
        lines: { show: false },
      },
    },
    tooltip: {
      // TODO: determine the type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      custom(data: any) {
        return `<div style='padding: 5px'><span>${
          data.series[data.seriesIndex][data.dataPointIndex]
        }%</span></div>`;
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
          fontWeight: 600,
        },
      },
    },
    theme: {
      mode: 'light',
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { color: theme.palette.divider },
      crosshairs: {
        stroke: { color: theme.palette.divider },
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
          fontWeight: 600,
        },
      },
      categories: data?.labels ?? [],
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
        },
      },
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 400,
          },
        },
      },
    ],
  };
  return (
    <ReactApexcharts
      type="line"
      height={400}
      options={options}
      series={[{ data: data?.series ?? [] }]}
    />
  );
};

export default LineChart;
