import styled from 'styled-components';

export const StyledTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: 0em;
  text-align: left;
  color: #1e242f;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const TitleDividerWrapper = styled.div`
  border-top: 5px solid #1e242f;
`;

export const Box = styled.div<{ paddingBlock?: string }>`
  padding-block: ${(props) => props.paddingBlock ?? '1rem'};

  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export default StyledTitle;
