import styled from 'styled-components';

export const StyledBreadcrumb = styled.ul`
  --breadcrumb-divider: '/';
  --breadcrumb-divider-color: var(--secondary-color-darker);
  --breadcrumb-color: black;
  --breadcrumb-active-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledBreadcrumbHolder = styled.div`
  display: flex;
  justify-content: 'flex-start';
  flex: 1;
`;

export const StyledBreadcrumbItem = styled.li`
  cursor: pointer;
  min-width: max-content;
  &:first-child {
    font-weight: 700;
  }

  &:not(:first-child) {
    padding-left: 1rem;
  }
  &:not(:first-child)::before {
    content: var(--breadcrumb-divider);
    padding-right: 1rem;
    color: var(--breadcrumb-divider-color);
  }
  &:last-child {
    cursor: default;
  }
`;

export const StyledBreadcrumbItemV2 = styled.li`
  cursor: pointer;
  min-width: max-content;
  font-weight: 700;
  &:last-child {
    font-weight: 400;
  }
  &:not(:first-child) {
    padding-left: 1rem;
  }
  &:not(:first-child)::before {
    content: var(--breadcrumb-divider);
    padding-right: 1rem;
    color: var(--breadcrumb-divider-color);
  }
  &:last-child {
    cursor: default;
  }
`;

export const StyledBreadcrumbText = styled.span`
  position: relative;
  text-decoration: none;

  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.0625rem;
    background: currentColor;
    transform: scaleX(0);
    transition: 0.3s;
  }

  &:hover {
    transition: all 0.3s ease-in-out;
    color: #31acbf;
  }

  &:hover::before {
    transform: scaleX(1);
    color: #31acbf;
  }
`;

export const StyledLastText = styled.span`
  position: relative;
  text-decoration: none;
  cursor: default;
`;

export default StyledBreadcrumb;
