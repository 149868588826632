import styled from 'styled-components';

export const StyledHeadingInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;

  border-bottom: 2px solid #1e242f;
`;

export const StyledTitle = styled.h2`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
`;

export const StyledBox = styled.span`
  line-height: 1;
  position: relative;
  z-index: 1;
  display: block;
  padding: 5px 20px;

  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: #1e242f;
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% - 0.001em);
    display: block;
    height: auto;
    content: '';
    border-width: calc(1em + 1px) 0 0 16px;
    border-style: solid;
    border-color: transparent transparent transparent #1e242f;
  }
`;

export const Box = styled.div`
  padding-block: 1rem;
`;
