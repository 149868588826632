import { BadgeProps } from './props';
import StyledBadge from './styles';

export const Badge: React.FC<BadgeProps> = (props) => {
  // ======================= PROPS
  const { label } = props;

  // ======================= VIEWS
  return <StyledBadge className="badge">{label}</StyledBadge>;
};

// ======================= EXPORT
export default Badge;
