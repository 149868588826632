import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import MUITableHead from '@mui/material/TableHead';
import size from 'lodash/size';

import { TableColumnType } from '../@types';

import { TableHeadProps } from './props';

export const TableHead = <T,>(props: TableHeadProps<T>) => {
  // ======================= PROPS
  const {
    checked = 'none',
    defaultOrderDirection = 'asc',
    defaultOrderBy,
    columns = [],
    tableRowProps,
    enableDivider,
    enableDividerOnLastElement = false,
    onSort,
    tableColumnTitle,
    onCheckAll,
    ...restProps
  } = props;

  // ======================= STATE
  const [orderByState, setOrderByState] = useState(defaultOrderBy);
  const [orderDirectionState, setOrderDirectionState] = useState(
    defaultOrderDirection
  );

  // ======================= EFFECTS
  useEffect(() => setOrderByState(defaultOrderBy), [defaultOrderBy]);
  useEffect(
    () => setOrderDirectionState(defaultOrderDirection),
    [defaultOrderDirection]
  );

  // ======================= EVENTS
  const onHandleSortClick = (key: string) => {
    return (e: React.MouseEvent) => {
      const isAsc = orderByState === key && orderDirectionState === 'asc';
      const nextDirection = isAsc ? 'desc' : 'asc';
      setOrderByState(key);
      setOrderDirectionState(nextDirection);
      onSort?.(e, key);
    };
  };

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS
  const renderColumnContent = (column: TableColumnType<T>) => {
    const {
      type = 'cell',
      key,
      title,
      sortable,
      uppercase = true,
      dataIndex,
    } = column;
    const isStringTitle = typeof title === 'string';
    const columnTitle =
      isStringTitle && uppercase ? title.toUpperCase() : title;
    const isCurrent = orderByState === key;

    if (type === 'cell') {
      if (!sortable)
        return (
          <Typography
            fontSize={12}
            fontWeight={600}
            whiteSpace={'nowrap'}
            {...tableColumnTitle}
          >
            {columnTitle}
          </Typography>
        );
      return (
        <TableSortLabel
          {...tableColumnTitle}
          active={isCurrent}
          direction={isCurrent ? orderDirectionState : 'asc'}
          onClick={onHandleSortClick(key)}
        >
          {columnTitle}
        </TableSortLabel>
      );
    }

    return (
      <Checkbox
        color="primary"
        indeterminate={checked === 'partial'}
        checked={checked === 'all'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!dataIndex) {
            console.error('dataIndex is not provided for checkbox type!');
            return;
          }
          const checkedState = event.target.checked;
          onCheckAll?.(checkedState, dataIndex);
        }}
        inputProps={{
          'aria-label': 'Select all',
        }}
      />
    );
  };

  // ======================= VIEWS
  if (columns.length === 0) return null;

  return (
    <MUITableHead
      {...restProps}
      sx={{
        bgcolor: '#F5F5F7',
        ...restProps?.sx,
      }}
    >
      <TableRow {...tableRowProps}>
        {columns.map((column, i) => {
          const { type = 'cell', key } = column;
          const isCurrent = orderByState === key;
          const lastColumnBorder =
            size(columns) - 1 === i ? 'hidden' : 'visible';
          return (
            <TableCell
              key={key}
              padding={type === 'checkbox' ? 'checkbox' : undefined}
              {...column.headerCellProps}
              sx={{
                width: column.width,
                ...column.headerCellProps?.sx,
                position: 'relative',
              }}
              align={column.align ?? 'left'}
              valign={'middle'}
              sortDirection={isCurrent ? defaultOrderDirection : false}
            >
              <Box
                {...column?.boxContainerProps}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  ...column?.boxContainerProps?.sx,
                }}
              >
                {renderColumnContent(column)}
                {enableDivider && (
                  <Stack
                    mr={-4}
                    px={2}
                    visibility={
                      enableDividerOnLastElement ? 'visible' : lastColumnBorder
                    }
                  >
                    <Box sx={{ background: '#E4E7ED', height: 18, width: 2 }} />
                  </Stack>
                )}
              </Box>
            </TableCell>
          );
        })}
      </TableRow>
    </MUITableHead>
  );
};

// ======================= EXPORT
export default TableHead;
