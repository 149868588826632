import { useEffect, useState } from 'react';
import MUITablePagination from '@mui/material/TablePagination';

import { TablePaginationProps } from './props';

export const TablePagination = <T,>(props: TablePaginationProps<T>) => {
  // ======================= PROPS
  const {
    data = [],
    page = 0,
    rowsPerPage = 10,
    rowsPerPageOptions = [10, 20, 30, 50],
    onPageChange,
    onRowsPerPageChange,
    ...restProps
  } = props;

  // ======================= HOOKS

  // ======================= STATE
  const [pageState, setPageState] = useState<number>(0);
  const [rowsPerPageState, setRowsPerPageState] = useState(rowsPerPage);

  useEffect(() => {
    setPageState(page);
  }, [page]);

  // ======================= API

  // ======================= EVENTS
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageState(newPage);
    onPageChange?.(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    setPageState(0);
    onPageChange?.(0);
    setRowsPerPageState(perPage);
    onRowsPerPageChange?.(perPage);
  };

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS

  // ======================= VIEWS
  return (
    <MUITablePagination
      component="div"
      {...restProps}
      sx={{
        // position: '-webkit-sticky',
        position: 'sticky',
        left: 0,
        ...restProps?.sx,
      }}
      count={restProps?.count ?? data.length}
      page={pageState}
      rowsPerPage={rowsPerPageState}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

// ======================= EXPORT
export * from './props';
export default TablePagination;
