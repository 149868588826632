import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Link from 'next/link';

import { BorderTitleProps } from './props';
import StyledTitle, { TitleDividerWrapper } from './styles';

export const BorderTitle: React.FC<BorderTitleProps> = (props) => {
  // ======================= PROPS
  const { title, url, children, containerProps } = props;

  const RenderRedirect: React.FC<{ children: ReactNode }> = (props) => {
    const { children } = props;
    return url ? <Link href={url}>{children}</Link> : <span>{children}</span>;
  };

  // ======================= VIEWS
  return (
    <Box className="box" {...containerProps}>
      <TitleDividerWrapper className="box-wrapper">
        <RenderRedirect>
          <StyledTitle $hoverEffect={!!url}>{title}</StyledTitle>
        </RenderRedirect>
        {children && <div className="box-children">{children}</div>}
      </TitleDividerWrapper>
    </Box>
  );
};

// ======================= EXPORT
export * from './props';
export default BorderTitle;
