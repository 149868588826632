import styled from 'styled-components';

export const StyledIcon = styled.span<{ $whiteText?: boolean }>`
  color: inherit;
  vertical-align: middle;
  font-weight: 700;
`;

export const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.disabled {
    pointer-events: none;
    color: #001e242f;

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    justify-content: center;
    width: 100%;
  }
`;

export const PaginationItem = styled.li`
  padding: 0 12px;
  height: 30px;
  text-align: center;
  margin: auto 4px;
  color: #1e242f;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  min-width: 30px;
  user-select: none;
  font-weight: 700;

  &.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  &:hover {
    background-color: rgb(247, 247, 247);
    cursor: pointer;
  }

  .icon {
    font-size: 20px;
  }

  &.selected {
    background-color: #31acbf;
    color: #fff;
    cursor: default;
  }

  &.disabled {
    pointer-events: none;
    color: #001e242f;

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;
