/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */
import React from 'react';
import { useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';

import { ChartProps } from '../props';
import ReactApexcharts from '../react-apexchart';

export const BarChart: React.FC<ChartProps> = (props) => {
  const { colors, data } = props;
  const theme = useTheme();

  const options: ApexOptions = {
    chart: {
      offsetX: -10,
      stacked: false,
      parentHeightOffset: 0,
      toolbar: { show: false },
      background: 'transparent',
    },
    theme: {
      mode: 'light',
    },
    fill: { opacity: 1 },
    dataLabels: {
      enabled: false,
    },

    colors,
    title: {
      text: data?.title,
      style: {
        color: '#374255',
      },
    },
    legend: {
      show: true,
      position: 'top',
    },
    stroke: {
      show: true,
      colors: ['transparent'],
      width: 6,
    },
    plotOptions: {
      bar: {
        columnWidth: '28px',
        borderRadius: 10,
        borderRadiusApplication: 'end',
        distributed: false,
        hideZeroBarsWhenGrouped: false,
      },
    },
    grid: {
      borderColor: '#E9ECF3',
      xaxis: {
        lines: { show: false },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    yaxis: {
      stepSize: 2,
      max: 12,
      forceNiceScale: true,
      labels: {
        // show: false,
        style: {
          colors: '#374255',
          fontWeight: 500,
        },

        formatter(val: number, opts?: any): string | string[] {
          if (val >= 1000) {
            return val / 1000 + 'k';
          } else {
            return val.toString();
          }
        },
      },
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { color: theme.palette.divider, show: false },
      categories: data?.labels ?? [],
      crosshairs: {
        stroke: { color: theme.palette.divider },
      },
      tickAmount: 12,
      labels: {
        style: {
          colors: '#374255',
          fontWeight: 600,
        },
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '14px',
              borderRadius: 7,
            },
          },
          chart: {
            height: 320,
          },
        },
      },
      {
        breakpoint: 1024,
        options: {
          plotOptions: {
            bar: {
              columnWidth: '20px',
              borderRadius: 10,
            },
          },
          chart: {
            height: 400,
          },
        },
      },
    ],
  };
  return (
    <ReactApexcharts
      type="bar"
      height={305}
      options={options}
      series={data?.series}
    />
  );
};

export default BarChart;
