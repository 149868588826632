export const DisableContainerStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  // change below value for background color rgba
  backgroundColor: 'rgba(209, 213, 219, 0.5)',
};

export const DisabledContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
};
