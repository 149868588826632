import { PropsWithChildren, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import {
  Box,
  Skeleton,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
} from '@mui/material';

import TableHead from '../TableHead';
import { TableHeadProps } from '../TableHead/props';
import TablePagination from '../TablePagination';
import WTableRow, { TableRowProps } from '../TableRow';

import { TableProps } from './props';
import { DisableContainerStyle, DisabledContentStyle } from './style';

export const Table = <T,>(props: PropsWithChildren<TableProps<T>>) => {
  // ======================= PROPS
  const {
    collapsible = false,
    disabled = false,
    disabledView,
    collapsibleColSpan,
    columns = [],
    checked = [],
    data = [],
    loading,
    tableBoxProps,
    loadingRows = 10,
    loadingView,
    emptyView,
    footerView,
    headerProps,
    bodyProps,
    footerProps,
    paginationProps,
    topExtraView,
    children,
    tableContainerProps,
    enableTableHeadDivider = true,
    onCheck,
    onRowClick,
    onRowExpand,
    renderExpandedView,
    tableStyle,
    tableCellLoadingProps,
    checkedCondition,
    onHandleCustomCheckItem,
    ...restProps
  } = props;

  // ======================= STATE
  const [checkedState, setCheckedState] = useState<T[]>(checked);

  // ======================= VARIABLES
  const hasData = data?.length > 0;
  const hasChecked = checkedState?.length > 0;

  // ================== HELPERS
  const getCheckedStatus = (): TableHeadProps<T>['checked'] => {
    if (hasChecked) {
      if (data.length === checkedState.length) return 'all';
      return 'partial';
    }
    return 'none';
  };
  // ======================= EFFECTS
  useDeepCompareEffect(() => setCheckedState(checked), [checked]);

  // ======================= EVENTS
  const triggerChange = (val: T[]) => {
    setCheckedState(val);
    onCheck?.(val);
  };

  const onHandleCheckAll: TableHeadProps<T>['onCheckAll'] = (
    state,
    dataIndex
  ) => {
    if (!state) {
      triggerChange([]);
      return;
    }
    triggerChange(
      data.reduce((r: T[], e) => {
        if (e[dataIndex]) {
          r.push(e);
        }
        return r;
      }, [])
    );
  };

  const onHandleCheckItem: TableRowProps<T>['onCheck'] = (
    record,
    checked,
    _meta
  ) => {
    if (onHandleCustomCheckItem)
      return onHandleCustomCheckItem({
        record,
        checked,
        triggerChange,
        checkedState,
      });
    if (checked) {
      triggerChange([...checkedState, record]);
    } else {
      triggerChange(checkedState.filter((e) => e !== record));
    }
  };

  // ======================= RENDER FUNCTIONS
  const renderTableBodyContent = () => {
    if (loading) {
      if (loadingView) return loadingView;
      return [...Array(loadingRows)].map((e, i) => (
        <TableRow key={i}>
          {columns.map((c) => {
            const mergedSx = {
              ...c.dataCellProps?.sx,
              ...tableCellLoadingProps?.sx,
            };
            return (
              <TableCell
                key={c.key}
                align="center"
                {...c.dataCellProps}
                {...tableCellLoadingProps}
                sx={{
                  width: c.width,
                  ...mergedSx,
                }}
              >
                <Skeleton height={30} />
              </TableCell>
            );
          })}
        </TableRow>
      ));
    }
    if (!hasData) {
      return (
        <TableRow sx={{ h: 100 }}>
          <TableCell colSpan={columns.length} align="center">
            {emptyView ?? (
              <Typography py={12} height={'100%'}>
                No data
              </Typography>
            )}
          </TableCell>
        </TableRow>
      );
    }
    return data.map((e, i) => {
      return (
        <WTableRow
          sx={{
            ...tableStyle?.(e),
          }}
          key={i}
          checked={checkedCondition?.(e) || checkedState.includes(e)}
          data={e}
          collapsible={collapsible}
          collapsibleColSpan={collapsibleColSpan}
          columns={columns}
          onClick={onRowClick ? (r, e) => onRowClick(r, e, i) : undefined}
          onExpand={onRowExpand ? (r, e) => onRowExpand(r, e, i) : undefined}
          onCheck={onHandleCheckItem}
          renderExpandedView={
            renderExpandedView
              ? (record, expanded) => renderExpandedView(record, expanded, i)
              : undefined
          }
        />
      );
    });
  };

  // ======================= VIEWS
  return (
    <TableContainer sx={{ position: 'relative' }} {...tableContainerProps}>
      <Box {...tableBoxProps}>
        {topExtraView}
        {disabled && (
          <Box sx={DisableContainerStyle}>
            {disabledView && (
              <Box sx={DisabledContentStyle}>{disabledView}</Box>
            )}
          </Box>
        )}
        <MUITable
          sx={{ opacity: loading ? 0.4 : 1, ...restProps.sx }}
          {...restProps}
        >
          <TableHead
            columns={columns}
            {...headerProps}
            onCheckAll={onHandleCheckAll}
            checked={getCheckedStatus()}
            enableDivider={enableTableHeadDivider}
          />
          <TableBody
            {...bodyProps}
            sx={{
              '.MuiTableRow-root > .MuiTableCell-root': {
                borderBottom: '1px solid #E5E5E5',
              },
            }}
          >
            {renderTableBodyContent()}
            {/* extra view with `children` */}
            {children}
          </TableBody>
          {footerView && (
            <TableFooter {...footerProps}>{footerView}</TableFooter>
          )}
        </MUITable>
      </Box>
      {paginationProps && <TablePagination data={data} {...paginationProps} />}
    </TableContainer>
  );
};

// ======================= EXPORT
export * from './props';
export default Table;
