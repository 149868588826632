// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from 'styled-components';

const commonStyle = {
  color: '#000',
  fontSize: '1.7rem',
};

export const StyledPrevIcon = styled(ChevronLeftIcon)({
  ...commonStyle,
});

export const StyledNextIcon = styled(ChevronRightIcon)({
  ...commonStyle,
});
