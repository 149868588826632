import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ApexOptions } from 'apexcharts';

import { ChartProps } from '../props';
import ReactApexcharts from '../react-apexchart';

export const PieChart: React.FC<ChartProps> = (props) => {
  const { colors, data, isMoney = false } = props;

  const options: ApexOptions = {
    chart: {
      background: 'transparent',
    },
    theme: {
      mode: 'light',
    },
    stroke: { width: 0 },
    labels: data?.labels ?? [],
    colors: colors,
    dataLabels: {
      enabled: false,
      formatter: (val: string) => `${parseInt(val, 10)}%`,
    },
    tooltip: {
      y: {
        formatter(val: number): string {
          if (isMoney) {
            return ReactDOMServer.renderToString(
              // <Money amount={val} currency={'RM'} />
              <div></div>
            );
          }
          return val.toString();
        },
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <ReactApexcharts
      type="pie"
      height={'auto'}
      options={options}
      series={data?.series ?? []}
    />
  );
};

export default PieChart;
