import styled from 'styled-components';

export const StyledTitle = styled.h2<{
  $hoverEffect: boolean;
}>`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: 0em;
  text-align: left;
  color: #1e242f;
  margin-top: 0rem;
  margin-bottom: 0;
  display: inline-flex;
  padding-left: 7px;
  border-left: 7px solid #1e242f;
  transition: all 0.3s ease-in-out;
  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  &:hover {
    color: ${(props) => (props.$hoverEffect ? '#31acbf' : '#1E242F')};
    border-color: ${(props) => (props.$hoverEffect ? '#31acbf' : '#1E242F')};
  }
`;

export const TitleDividerWrapper = styled.div``;

export default StyledTitle;
