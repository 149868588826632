import styled from 'styled-components';

export const StyledBadge = styled.span`
  background-color: #ffc800;
  color: #1e242f;
  padding: 4px 8px;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  max-width: fit-content;
  position: absolute;
  top: 0;
  left: 0;
`;

export default StyledBadge;
